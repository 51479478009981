var CollectionPicker,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

CollectionPicker = (function(superClass) {
  var validate_collections_selection;

  extend(CollectionPicker, superClass);

  function CollectionPicker() {
    return CollectionPicker.__super__.constructor.apply(this, arguments);
  }

  validate_collections_selection = function(e) {
    var collections, form, positions, validation;
    collections = [];
    positions = [];
    form = $(".js-collection-picker-form");
    validation = true;
    form.find(".collection-selection.selectpicker:not(.newelement)").each(function() {
      var msg, value;
      if ($(this).parents(".hide").length === 0) {
        value = $(this).val();
        if (collections.indexOf(value) !== -1) {
          e.preventDefault();
          msg = form.find("#duplicate-collection");
          msg.show();
          $(this).focus();
          $(this).selectpicker('setStyle', 'btn-warning');
          window.setTimeout(function() {
            return msg.hide();
          }, 6000);
          return validation = false;
        } else {
          $(this).selectpicker('setStyle', 'btn-warning', "remove");
          $(this).selectpicker('setStyle', 'btn-default');
          return collections.push(value);
        }
      }
    });
    form.find(".collection-position.selectpicker:not(.newelement)").each(function() {
      var msg, value;
      if ($(this).parents(".hide").length === 0) {
        value = $(this).val();
        if (positions.indexOf(value) !== -1) {
          e.preventDefault();
          msg = form.find("#duplicate-position");
          msg.show();
          $(this).focus();
          $(this).selectpicker('setStyle', 'btn-warning');
          window.setTimeout(function() {
            return msg.hide();
          }, 6000);
          return validation = false;
        } else {
          $(this).selectpicker('setStyle', 'btn-warning', "remove");
          $(this).selectpicker('setStyle', 'btn-default');
          return positions.push(value);
        }
      }
    });
    return validation;
  };

  CollectionPicker.prototype.prepare_form_data = function(e) {
    var validation;
    validation = validate_collections_selection(e);
    if (validation) {
      $(".js-collection-picker-form").find(".new-column").remove();
    }
    return validation;
  };

  CollectionPicker.prototype.bind_selectpicker = function(e) {
    return window.setTimeout(function() {
      var index;
      index = parseInt($("#collection-list-index").val());
      $(".columns .js-selection").each(function() {
        var collection_name;
        collection_name = $(this).data("collection-name");
        if ($(this).attr("name") === (collection_name + "_data")) {
          $(this).attr("name", collection_name + ".selected_collections[" + index + "][data]");
        }
        if ($(this).attr("name") === (collection_name + "_position")) {
          $(this).attr("name", collection_name + ".selected_collections[" + index + "][position]");
        }
        return $(this).selectpicker();
      });
      $("#collection-list-index").val(index + 1);
      return $(".columns .js-selection").each(function() {
        if (!$(this).hasClass("bootstrap-select")) {
          $(this).addClass("selectpicker");
        }
        return $(this).removeClass("js-selection");
      });
    }, 500);
  };

  CollectionPicker.prototype.initialize = function() {
    $(".btn-details-save").on("click", this.prepare_form_data);
    return $(".js-add-collection").on("click", this.bind_selectpicker);
  };

  return CollectionPicker;

})(Backbone.View);

module.exports = CollectionPicker;
