var Article,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Article = (function(superClass) {
  extend(Article, superClass);

  function Article() {
    return Article.__super__.constructor.apply(this, arguments);
  }

  Article.prototype.initialize = function() {
    var scope;
    this.inputResize($('.js-autoheight'));
    scope = this;
    $('.js-autoheight').on('input', function(e) {
      return scope.inputResize($(this));
    });
    return $(document).on("data-added", function(e, element) {
      var $el;
      $el = $(element);
      if ($el.hasClass("js-content-block content-block--image") && !$el.hasClass("template")) {
        return $(element).find("input[type=file]").fileinput();
      }
    });
  };

  Article.prototype.inputResize = function($el) {
    return $el.css({
      'height': 'auto',
      'overflow-y': 'hidden'
    }).height($el[0].scrollHeight);
  };

  return Article;

})(Backbone.View);

module.exports = Article;
