var MenuItem,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

MenuItem = (function(superClass) {
  var move_down_fn, move_up_fn, prepare_form_data, select_website, update_all_move_btns, update_move_btns, update_move_columns_btns, update_move_elements_btns;

  extend(MenuItem, superClass);

  function MenuItem() {
    return MenuItem.__super__.constructor.apply(this, arguments);
  }

  move_down_fn = function(item_selector, update_move_btns) {
    update_move_btns();
    return function(e) {
      var $elt, $item_element, $next_item_element;
      $elt = $(e.currentTarget);
      $item_element = $elt.parents(item_selector);
      $next_item_element = $item_element.next(item_selector);
      if ($next_item_element.length > 0) {
        $($item_element).insertAfter($next_item_element);
        return update_move_btns();
      }
    };
  };

  move_up_fn = function(item_selector, update_move_btns) {
    update_move_btns();
    return function(e) {
      var $elt, $item_element, $prev_item_element;
      $elt = $(e.currentTarget);
      $item_element = $elt.parents(item_selector);
      $prev_item_element = $item_element.prev(item_selector);
      if ($prev_item_element.length > 0) {
        $($item_element).insertBefore($prev_item_element);
        return update_move_btns();
      }
    };
  };

  update_move_btns = function(btn_selector_up, btn_selector_down, item_selector) {
    $("#menu_item_form").find(btn_selector_up).each(function() {
      var $btn_el, $item_element;
      $btn_el = $(this);
      $item_element = $btn_el.parents(item_selector);
      return $btn_el.toggleClass("disabled", $item_element.prev(item_selector).length === 0);
    });
    return $("#menu_item_form").find(btn_selector_down).each(function() {
      var $btn_el, $item_element;
      $btn_el = $(this);
      $item_element = $btn_el.parents(item_selector);
      return $btn_el.toggleClass("disabled", $item_element.next(item_selector).length === 0);
    });
  };

  update_move_elements_btns = function() {
    return update_move_btns(".move-element-up", ".move-element-down", ".element.row:not(.new-element)");
  };

  update_move_columns_btns = function() {
    return update_move_btns(".move-column-up", ".move-column-down", ".column:not(.new-column)");
  };

  update_all_move_btns = function() {
    update_move_elements_btns();
    return update_move_columns_btns();
  };

  prepare_form_data = function() {
    var $el;
    $el = $("#menu_item_form");
    $el.find(".columns .column:not(.new-column)").each(function(col_index) {
      $(this).find(".element.row:not(.new-element)").each(function(row_index) {
        $(this).find("input").attr("name", function(i, name) {
          var $fieldEl;
          $fieldEl = $(this);
          name = $fieldEl.data("name");
          return "columns[" + col_index + "][" + row_index + "][" + name + "]";
        });
        return true;
      });
      return true;
    });
    return true;
  };

  select_website = function(e, data) {
    var color, website;
    website = $(e.currentTarget).val();
    color = $(".color-picker");
    if (website === "cigars.com") {
      if (color.val() === "#ffffff") {
        return color.val("#333333");
      }
    } else {
      if (color.val() === "#333333") {
        return color.val("#ffffff");
      }
    }
  };

  $("#menu_item_form").on("click", ".move-element-up", move_up_fn(".element.row:not(.new-element)", update_move_elements_btns));

  $("#menu_item_form").on("click", ".move-element-down", move_down_fn(".element.row:not(.new-element)", update_move_elements_btns));

  $("#menu_item_form").on("click", ".move-column-up", move_up_fn(".column:not(.new-column)", update_move_columns_btns));

  $("#menu_item_form").on("click", ".move-column-down", move_down_fn(".column:not(.new-column)", update_move_columns_btns));

  $(".btn-details-save").on("click", prepare_form_data);

  $(document).on("data-added", update_all_move_btns);

  $(document).on("data-added", function(e, element) {
    var $el;
    $el = $(element);
    if ($el.hasClass("element-image") && !$el.hasClass("new-element")) {
      return $(element).find("input[type=file]").fileinput();
    }
  });

  $(document).on("data-removed", update_all_move_btns);

  $(document).on("change", ".website-radio-btn", select_website);

  $("form").on("saved", function(e, data) {
    return setTimeout(function() {
      return document.location.href = "/menu_item/" + data._id;
    }, 500);
  });

  return MenuItem;

})(Backbone.View);

module.exports = MenuItem;
