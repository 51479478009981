var DynamicFilter,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

DynamicFilter = (function(superClass) {
  extend(DynamicFilter, superClass);

  function DynamicFilter() {
    this.select_user_li = bind(this.select_user_li, this);
    this.change_user_text = bind(this.change_user_text, this);
    this.prepare_form_data = bind(this.prepare_form_data, this);
    return DynamicFilter.__super__.constructor.apply(this, arguments);
  }

  DynamicFilter.prototype.initialize = function() {
    $(".select-property").on("change", function(e) {
      $(".property-selector").attr("data-add", e.currentTarget.value);
      return $(".property-selector").data("add", e.currentTarget.value);
    });
    $(".btn-details-save").on("click", this.prepare_form_data);
    $(".property-selector").on("click", (function(_this) {
      return function() {
        return setTimeout(function() {
          return _this.update_property_indexes();
        }, 100);
      };
    })(this));
    $(document).on("change", ".attribute-type", this.change_attribute_type);
    $(document).on("input", ".user-textarea", this.change_user_text);
    $(document).on("click", ".user-li", this.select_user_li);
    return $("form").on("saved", function(e, data) {
      return setTimeout(function() {
        return document.location.reload();
      }, 500);
    });
  };

  DynamicFilter.prototype.update_property_indexes = function(e) {
    $(".properties .user-selector").each(function(s) {
      var $s;
      $s = $(this);
      if ($s.hasClass("selectpicker")) {
        return;
      }
      $s.addClass("selectpicker");
      return $s.selectpicker();
    });
    return $(".js-dynamic-filter-element").each(function(i) {
      var entity_name;
      entity_name = $(this).data("entity");
      $(this).find("[name*='" + entity_name + ".dynamic_filter']").each(function() {
        var input, name;
        input = $(this);
        name = input.attr("name").replace(/\[\d+\]/, "[" + i + "]").replace(/\[\d+\]$/, "");
        return input.attr("name", name);
      });
      return $(this).find("[data-relatedto*='" + entity_name + ".dynamic_filter']").each(function() {
        var $el, name;
        $el = $(this);
        name = $el.attr("data-relatedto").replace(/\[\d+\]/, "[" + i + "]").replace(/\[\d+\]$/, "");
        return $el.attr("data-relatedto", name);
      });
    });
  };

  DynamicFilter.prototype.prepare_form_data = function(e) {
    $(".property-list").remove();
    this.update_property_indexes(e);
    return true;
  };

  DynamicFilter.prototype.change_attribute_type = function(e) {
    var entity_name, input, type;
    input = $(e.currentTarget);
    type = input.val();
    entity_name = $(this).data("entity");
    input.parents(".row").find("[data-attribute]").addClass("hide");
    input.parents(".row").find("[data-attribute] select").attr("name", "");
    input.parents(".row").find("[data-attribute='" + type + "']").removeClass("hide");
    input.parents(".row").find("[data-attribute='" + type + "'] select").attr("name", entity_name + ".dynamic_filter[0][value]");
    input.parents(".row").find(".range").toggleClass("hide", !/(length|ring)/.test(type));
    return input.parents(".row").find(".value").toggleClass("hide", /(length|ring)/.test(type));
  };

  DynamicFilter.prototype.change_user_text = function(e) {
    var $text, cursor, query, ref, search, sort, url, val;
    $text = $(e.currentTarget);
    val = $text.val();
    cursor = $text.prop("selectionStart");
    if (val.length !== cursor) {
      return;
    }
    search = _.last($text.val().split(","));
    query = JSON.stringify({
      screen_name: search
    });
    sort = JSON.stringify({
      screen_name: "asc"
    });
    url = "/customer_search/0/20/" + query + "/" + sort;
    if ((ref = this.current_ajax_search_call) != null) {
      if (typeof ref.abort === "function") {
        ref.abort();
      }
    }
    return this.current_ajax_search_call = $.ajax({
      type: "get",
      url: url,
      success: (function(_this) {
        return function(res) {
          var $dropdown, lis;
          if (res.customers.length === 0) {
            $text.parents(".dropdown").removeClass("open");
            return;
          }
          $text.parents(".dropdown").addClass("open");
          $dropdown = $($text.siblings(".dropdown-menu"));
          lis = res.customers.map(function(c) {
            var name;
            name = c.email;
            if (c.screen_name) {
              name += " - " + c.screen_name;
            } else if (c.first_name && c.last_name) {
              name += " - " + c.first_name + " " + c.last_name;
            }
            name += " (" + c.identifier + ")";
            return "<li class='user-li' data-email=" + c.email + "><a>" + name + "</a></li>";
          });
          return $dropdown.html(lis.join(""));
        };
      })(this),
      error: (function(_this) {
        return function(err) {};
      })(this)
    });
  };

  DynamicFilter.prototype.select_user_li = function(e) {
    var $li, $text, split;
    $li = $(e.currentTarget);
    $text = $($li.parents(".row").find(".user-textarea"));
    split = $text.val().split(",");
    split[split.length - 1] = $li.data("email");
    $text.val(split.join(","));
    return $text.parents(".dropdown").removeClass("open");
  };

  return DynamicFilter;

})(Backbone.View);

module.exports = DynamicFilter;
