var PrimeEditor,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

PrimeEditor = (function(superClass) {
  var add_prime, current_popup, init_popup, save_changes;

  extend(PrimeEditor, superClass);

  function PrimeEditor() {
    return PrimeEditor.__super__.constructor.apply(this, arguments);
  }

  current_popup = {};

  init_popup = function(e) {
    var trigger_element;
    trigger_element = $(e.currentTarget);
    current_popup.index = trigger_element.attr("data-index");
    current_popup.id = "\#prime-modal-" + current_popup.index;
    return current_popup.target_row = trigger_element.attr("data-relatedto");
  };

  save_changes = function(e) {
    var popup_when_saved, values;
    popup_when_saved = current_popup;
    $("#save").removeClass("btn-success").addClass("btn-primary").html("<img src='/cms/img/loader-dark.gif' width=16>&nbsp;Saving").blur();
    values = {
      'customer': $("form [name='customer._id']").val(),
      'index': popup_when_saved.index,
      'active': $(popup_when_saved.id + " input[name='active']").is(":checked"),
      'renewal': $(popup_when_saved.id + " input[name='renewal']").is(":checked"),
      'prime_subscription.start': $(popup_when_saved.id + " input[name='prime_subscription.subscription_period.start']").val(),
      'prime_subscription.end': $(popup_when_saved.id + " input[name='prime_subscription.subscription_period.end']").val()
    };
    return $.ajax({
      type: "POST",
      url: "/customer/save_prime",
      data: values,
      success: function(e) {
        var fmat;
        $("#save").removeClass("btn-primary").addClass("btn-success").html("<span class='glyphicon glyphicon-ok' />&nbsp;Saved");
        fmat = function(d) {
          return moment(d).format("MM/D/YYYY h:mm a");
        };
        $(popup_when_saved.target_row + " .sub-active").html(e.prime_subscriptions[values.index].active ? "true" : "false");
        $(popup_when_saved.target_row + " .sub-renewal").html(e.prime_subscriptions[values.index].renewal ? "true" : "false");
        $(popup_when_saved.target_row + " .sub-start").html(fmat(e.prime_subscriptions[values.index].subscription_period.start));
        $(popup_when_saved.target_row + " .sub-end").html(fmat(e.prime_subscriptions[values.index].subscription_period.end));
        return $(popup_when_saved.target_row + " .sub-modified").html(fmat());
      },
      error: function(e) {
        $("#save").html("Save");
        if (e.responseText) {
          alert(e.responseText);
        }
        if (e.status === 401) {
          return document.location.href = "/";
        }
      }
    });
  };

  add_prime = function() {
    return $.ajax({
      type: "POST",
      url: "/customer/add_prime",
      data: {
        customer_id: $("#id").val()
      },
      success: function(e) {
        return document.location.reload();
      },
      error: function(e) {
        if (e.responseText) {
          alert(e.responseText);
        }
        if (e.status === 401) {
          return document.location.href = "/";
        }
      }
    });
  };

  $(".prime-edit-trigger").on("click", init_popup);

  $(".prime-editor-modal button.save-changes").on("click", save_changes);

  $(".btn.add-prime").on("click", add_prime);

  return PrimeEditor;

})(Backbone.View);

module.exports = PrimeEditor;
