var CollectionList,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

CollectionList = (function(superClass) {
  extend(CollectionList, superClass);

  function CollectionList() {
    return CollectionList.__super__.constructor.apply(this, arguments);
  }

  CollectionList.prototype.initialize = function() {
    $("[name='collection_list.title']").on("change keyup paste input", function(e) {
      var value;
      value = $(e.currentTarget).val();
      value = value.replace(/\s+/g, '-').toLowerCase();
      $("[name='collection_list.url']").val(value);
      return $("[name='collection_list.url']").change();
    });
    return $("[name='collection_list.url']").on("change keyup paste input", function(e) {
      var value;
      value = $(e.currentTarget).val();
      return $(".js-collection-list-url").text("cigars.com/list/" + value);
    });
  };

  return CollectionList;

})(Backbone.View);

module.exports = CollectionList;
