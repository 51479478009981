
/*
  Application
  A simple object that initializes views based on data attributes of
  elements in the DOM. It extends Backbone Events so that it can act as a
  communication layer between different views. It is initialized directly
  on each page once the document is ready.
 */
var Application;

Application = _.extend({}, Backbone.Events, {
  initialize: function() {
    $("[data-lib]").each(function(i, el) {
      var current_lib, e, libs;
      libs = {};
      current_lib = $(el).data("lib");
      console.debug("js libs");
      try {
        if (libs[current_lib] == null) {
          require("js_libs/" + current_lib);
          return libs[current_lib];
        }
      } catch (error) {
        e = error;
        return console.log(e);
      }
    });
    $("[data-form]").each(function(i, el) {
      console.log("generic form", el);
      return new (require("coffee/components/generic_form"))({
        el: el
      });
    });
    $("[data-table]").each(function(i, el) {
      var e;
      console.debug("generic table");
      try {
        return new (require("coffee/components/generic_table"))({
          el: el
        });
      } catch (error) {
        e = error;
        return console.log(e);
      }
    });
    $("[data-component]").each(function(i, el) {
      var e;
      try {
        return new (require("coffee/components/" + ($(el).data("component"))))({
          el: el
        });
      } catch (error) {
        e = error;
        return console.log(e);
      }
    });
    return $("[data-view]").each(function(i, el) {
      var e;
      console.debug("comp:", $(el).data("view"));
      try {
        return new (require("coffee/pages/" + ($(el).data("view"))))({
          el: el
        });
      } catch (error) {
        e = error;
        return console.log(e);
      }
    });
  }
});

module.exports = Application;
