var Ap,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Ap = (function(superClass) {
  extend(Ap, superClass);

  function Ap() {
    return Ap.__super__.constructor.apply(this, arguments);
  }

  initialize(function() {
    return $("input[name='sbt']").click();
  });

  return Ap;

})(Backbone.View);

module.exports = Ap;
