var Coupon,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Coupon = (function(superClass) {
  extend(Coupon, superClass);

  function Coupon() {
    return Coupon.__super__.constructor.apply(this, arguments);
  }

  Coupon.prototype.select_is_global = function() {
    var $checkbox, $toggleElem, $toggleExclItemsElem;
    $checkbox = $("[name='coupon.is_global']");
    $toggleElem = $("#" + $checkbox.attr("data-jrc-toggle-id"));
    $toggleExclItemsElem = $("#coupon-exclude-item-codes");
    if ($checkbox.prop('checked')) {
      $toggleElem.hide();
      return $toggleExclItemsElem.show();
    } else {
      $toggleElem.show();
      return $toggleExclItemsElem.hide();
    }
  };

  Coupon.prototype.select_coupon_type = function() {
    var coupon_type;
    coupon_type = $("[name='coupon.type']").val();
    switch (coupon_type) {
      case "Total Order Discount based on Affiliation":
        $("#coupon-is-global").hide();
        $("#affiliations-config").show();
        $("[name='coupon.is_global']").prop("checked", true);
        break;
      case "Total Order Discount":
        $("#coupon-is-global").hide();
        $("#affiliations-config").hide();
        $("[name='coupon.is_global']").prop("checked", true);
        break;
      default:
        $("#affiliations-config").hide();
        $("#coupon-is-global").show();
    }
    return this.select_is_global();
  };

  Coupon.prototype.initialize = function() {
    $("[name='coupon.is_global']").on("change", this.select_is_global);
    this.select_is_global();
    $("[name='coupon.type']").on("change", this.select_coupon_type);
    return this.select_coupon_type();
  };

  return Coupon;

})(Backbone.View);

module.exports = Coupon;
