var Customer,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Customer = (function(superClass) {
  extend(Customer, superClass);

  function Customer() {
    return Customer.__super__.constructor.apply(this, arguments);
  }

  Customer.prototype.initialize = function() {
    return $(".pickup-bid").on("click", function() {
      var auction_id, field, winner_id;
      if (confirm("Do you want to update this win?")) {
        winner_id = $(this).data("winner");
        auction_id = $("input[name=\"auction.id\"]").val();
        field = $(this).parents("td");
        return $.ajax({
          type: "POST",
          url: "/bid/pickup",
          data: {
            auction_id: auction_id,
            winner_id: winner_id
          },
          success: function(r) {
            return field.html("Order placed");
          },
          error: function(e) {
            return alert(e);
          }
        });
      }
    });
  };

  return Customer;

})(Backbone.View);

module.exports = Customer;
