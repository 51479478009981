var Category,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Category = (function(superClass) {
  var changeSelectionState, removeBrandsSelection, showLayoutDescription;

  extend(Category, superClass);

  function Category() {
    return Category.__super__.constructor.apply(this, arguments);
  }

  Category.prototype.validate_brands_selection = function(e) {
    var brands, form, positions;
    brands = [];
    positions = [];
    form = $("#category_form");
    form.find(".brand-selection:not(.newelement)").each(function() {
      var msg, value;
      if ($(this).parents(".newelement").length === 0) {
        value = $(this).val();
        if (brands.indexOf(value) !== -1) {
          e.preventDefault();
          msg = form.find("#duplicate-brand");
          msg.show();
          window.setTimeout(function() {
            return msg.hide();
          }, 2000);
          return false;
        } else {
          return brands.push(value);
        }
      }
    });
    return form.find(".brand-position:not(.newelement)").each(function() {
      var msg, value;
      if ($(this).parents(".newelement").length === 0) {
        value = $(this).val();
        if (positions.indexOf(value) !== -1) {
          e.preventDefault();
          msg = form.find("#duplicate-position");
          msg.show();
          window.setTimeout(function() {
            return msg.hide();
          }, 2000);
          return false;
        } else {
          return positions.push(value);
        }
      }
    });
  };

  Category.prototype.prepare_form_data = function(e) {
    this.validate_brands_selection(e);
    $("#category_form").find(".new-column").remove();
    return true;
  };

  showLayoutDescription = function(layout) {
    var $allbrands_large, $allbrands_small, $popular_brands, $topbrands, el, i, len, ref;
    $popular_brands = $("#popular-brands-description");
    $topbrands = $("#top-brands-description");
    $allbrands_small = $("#all-brands-small-description");
    $allbrands_large = $("#all-brands-large-description");
    ref = [$popular_brands, $topbrands, $allbrands_small, $allbrands_large];
    for (i = 0, len = ref.length; i < len; i++) {
      el = ref[i];
      el.hide();
    }
    switch (layout) {
      case 'popular_brands':
        return $popular_brands.show();
      case 'top_brands':
        return $topbrands.show();
      case 'all_brands_small':
        return $allbrands_small.show();
      case 'all_brands_large':
        return $allbrands_large.show();
    }
  };

  changeSelectionState = function(selection, disabled) {
    var el, i, len, ref, results;
    if (disabled) {
      selection.hide();
    } else {
      selection.show();
    }
    ref = [".brand-selection", ".brand-position"];
    results = [];
    for (i = 0, len = ref.length; i < len; i++) {
      el = ref[i];
      results.push(selection.find(el).prop("disabled", disabled));
    }
    return results;
  };

  removeBrandsSelection = function(klass) {
    return $("." + klass + ":not(.newelement)").remove();
  };

  Category.prototype.toggleBrandsSelectionPanel = function() {
    var brands_layout, form, panel;
    form = $("#category_form");
    panel = $("#brands-selection");
    brands_layout = $("#layout-selection").val();
    showLayoutDescription(brands_layout);
    if (brands_layout === "top_brands" || brands_layout === "popular_brands") {
      panel.fadeIn();
    } else {
      panel.fadeOut();
    }
    if (brands_layout === "top_brands") {
      form.find(".children").each(function() {
        return changeSelectionState($(this), true);
      });
      form.find(".grandchildren").each(function() {
        return changeSelectionState($(this), false);
      });
      return removeBrandsSelection("children");
    } else {
      form.find(".children").each(function() {
        return changeSelectionState($(this), false);
      });
      form.find(".grandchildren").each(function() {
        return changeSelectionState($(this), true);
      });
      return removeBrandsSelection("grandchildren");
    }
  };

  Category.prototype.initialize = function() {
    this.toggleBrandsSelectionPanel();
    $("#layout-selection").on("change", this.toggleBrandsSelectionPanel);
    $(".btn-details-save").on("click", function(e) {
      return this.prepare_form_data(e);
    });
    return $("form").on("saved", function(e, data) {
      return setTimeout(function() {
        return document.location.href = "/category/" + data._id;
      }, 500);
    });
  };

  return Category;

})(Backbone.View);

module.exports = Category;
