var Email,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Email = (function(superClass) {
  extend(Email, superClass);

  function Email() {
    return Email.__super__.constructor.apply(this, arguments);
  }

  Email.prototype.initialize = function() {};

  Email.prototype.events = {
    "click a.js-method": "clickTestAction"
  };

  Email.prototype.clickTestAction = function(e) {
    var email, url;
    e.preventDefault();
    email = $("[name='email']").val();
    url = e.currentTarget.href + "/" + email + "/" + e.currentTarget.dataset.method;
    return $.ajax({
      url: url,
      type: "GET",
      success: function(html) {
        if ((html != null) && html !== "") {
          return $(".preview").html(html);
        } else {
          return alert("Email Sent!");
        }
      },
      error: function(err) {
        alert("Error!");
        return console.error(err);
      }
    });
  };

  return Email;

})(Backbone.View);

module.exports = Email;
