var NewsArticle,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

NewsArticle = (function(superClass) {
  extend(NewsArticle, superClass);

  function NewsArticle() {
    return NewsArticle.__super__.constructor.apply(this, arguments);
  }

  NewsArticle.prototype.select_date_range = function() {
    var date_range_type;
    date_range_type = $("[name='date_range_type']").val();
    if (date_range_type === "active" || date_range_type === "expired") {
      $("[name='planning.start']").prop("disabled", true);
      return $("[name='planning.end']").prop("disabled", true);
    } else {
      $("[name='planning.start']").prop("disabled", false);
      return $("[name='planning.end']").prop("disabled", false);
    }
  };

  NewsArticle.prototype.initialize = function() {
    $("[name='date_range_type']").on("change", this.select_date_range);
    return this.select_date_range();
  };

  return NewsArticle;

})(Backbone.View);

module.exports = NewsArticle;
