var Page,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Page = (function(superClass) {
  extend(Page, superClass);

  function Page() {
    return Page.__super__.constructor.apply(this, arguments);
  }

  Page.prototype.initialize = function() {
    if (typeof CKEDITOR !== "undefined") {
      return CKEDITOR.replace('editor', {
        height: '600px'
      });
    }
  };

  return Page;

})(Backbone.View);

module.exports = Page;
