var Banner,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Banner = (function(superClass) {
  extend(Banner, superClass);

  function Banner() {
    return Banner.__super__.constructor.apply(this, arguments);
  }

  Banner.prototype.initialize = function() {
    if ($("[name='banner.layout']").find(":selected").val() !== "rich_content_banner") {
      $("section#layout").hide();
    }
    $("[name='banner.layout']").on("change", function(e) {
      var element, nb_images, selected_value;
      element = $(e.currentTarget);
      nb_images = parseInt(element.find(":selected").attr("nb_images"));
      $(".elements li").each(function(i, el) {
        var index;
        index = parseInt($(el).attr("index"));
        if (parseInt($(el).attr("index")) > nb_images) {
          return $(el).hide();
        } else {
          return $(el).show();
        }
      });
      selected_value = element.find(":selected").val();
      element = $("section#layout");
      if (selected_value === "rich_content_banner") {
        return element.show();
      } else {
        return element.hide();
      }
    });
    if ($(".js-button-type:checked").val() !== "video") {
      $(".js-field-video").hide();
    }
    return $(".js-button-type").on("change", function(e) {
      var selected_value;
      selected_value = $(this).val();
      if (selected_value === "link") {
        $(".js-field-link").show();
        return $(".js-field-video").hide();
      } else {
        $(".js-field-link").hide();
        return $(".js-field-video").show();
      }
    });
  };

  return Banner;

})(Backbone.View);

module.exports = Banner;
