var TheLatestSource,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

TheLatestSource = (function(superClass) {
  extend(TheLatestSource, superClass);

  function TheLatestSource() {
    return TheLatestSource.__super__.constructor.apply(this, arguments);
  }

  TheLatestSource.prototype.initialize = function() {
    return $("[name='thelatest_source.url']").on("change keyup paste input", function(e) {
      var ref, ref1, user_id, value;
      value = $(e.currentTarget).val();
      if ((value == null) || value.trim() === "") {
        value = "";
      } else if (((ref = value.match(/channel\/(.*)$/)) != null ? ref.length : void 0) === 2) {
        value = value.match(/channel\/(.*)$/)[1];
      } else if (((ref1 = value.match(new RegExp("instagram", "i"))) != null ? ref1.length : void 0) > 0) {
        user_id = value.split("/");
        if ((user_id != null ? user_id.length : void 0) > 0) {
          value = user_id[user_id.length - 1] || user_id[user_id.length - 2];
        }
      } else {
        value = "";
      }
      return $("[name='thelatest_source.id']").val(value);
    });
  };

  return TheLatestSource;

})(Backbone.View);

module.exports = TheLatestSource;
