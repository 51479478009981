var ManageDetails,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

ManageDetails = (function(superClass) {
  extend(ManageDetails, superClass);

  function ManageDetails() {
    return ManageDetails.__super__.constructor.apply(this, arguments);
  }

  ManageDetails.prototype.initialize = function() {
    $(".add").click(function(e) {
      var $el, detail, new_value, product;
      e.preventDefault();
      $el = $(e.currentTarget);
      detail = $el.data('detail');
      product = $el.data('product');
      new_value = window.prompt("Please enter the new value: ");
      if (new_value) {
        return $.ajax({
          url: "/manage_details/add/" + product + "/" + detail + "?new_value=" + new_value,
          type: "POST",
          success: function(e) {
            return document.location.reload();
          },
          error: function(e) {
            return alert("There was an error creating this new value " + e);
          }
        });
      }
    });
    $(".edit").click(function(e) {
      var $el, detail, elt, input, old_value, product;
      $el = $(e.currentTarget);
      detail = $el.data('detail');
      product = $el.data('product');
      elt = $el.parents("tr").find("td.editable");
      old_value = elt.html();
      input = $("<input type='text' class='form-control' value='" + old_value + "'/>");
      elt.html(input);
      return input.on("keyup", function(e) {
        var new_val;
        if (e.keyCode === 13) {
          new_val = input.val();
          elt.html(new_val);
          return $.ajax({
            url: "/manage_details/update/" + product + "/" + detail + "?old_value=" + old_value + "&new_value=" + new_val,
            type: "POST",
            success: function(e) {},
            error: function(e) {}
          });
        }
      });
    });
    return $(".remove_detail").click(function(e) {
      var $el, detail_name, detail_product, elt, on_success, url, value;
      $el = $(e.currentTarget);
      if (confirm('Are you sure?')) {
        elt = $el;
        url = $el.data("url");
        value = $el.parents("tr").find(".editable").html();
        detail_name = $el.data("detail");
        detail_product = $el.data("product");
        on_success = $el.data("success");
        return $.ajax({
          type: "POST",
          url: "/manage_details/remove/" + detail_product + "/" + detail_name + "?value=" + value,
          success: function(e) {
            return elt.parents("tr").remove();
          }
        });
      }
    });
  };

  return ManageDetails;

})(Backbone.View);

module.exports = ManageDetails;
