var Deals,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Deals = (function(superClass) {
  extend(Deals, superClass);

  function Deals() {
    return Deals.__super__.constructor.apply(this, arguments);
  }

  Deals.prototype.select_date_range = function() {
    var date_range_type;
    date_range_type = $("[name='date_range_type']").val();
    if (date_range_type === "active" || date_range_type === "expired") {
      $("[name='planning.start']").prop("disabled", true);
      return $("[name='planning.end']").prop("disabled", true);
    } else {
      $("[name='planning.start']").prop("disabled", false);
      return $("[name='planning.end']").prop("disabled", false);
    }
  };

  Deals.prototype.enable_dnd = function(enable) {
    var rows, table, table_dnd_event;
    table_dnd_event = "touchstart mousedown";
    table = $("table.resortable");
    rows = table.find("tr");
    if (enable) {
      return rows.removeClass("nodrag");
    } else {
      rows.addClass("nodrag");
      table.unbind(table_dnd_event);
      return rows.each(function() {
        return $(this).unbind(table_dnd_event);
      });
    }
  };

  Deals.prototype.initialize = function() {
    $("table").on("update", function() {
      this.enable_dnd($("[name='date_range_type']").val() === "active");
      return setTimeout(function() {
        return $("table.resortable").tableDnDUpdate();
      }, 0);
    });
    $("[name='date_range_type']").on("change", this.select_date_range);
    return this.select_date_range();
  };

  return Deals;

})(Backbone.View);

module.exports = Deals;
