var ItemPicker,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

ItemPicker = (function(superClass) {
  var current_popup, display_item_picker_categories, format_text_area, get_query, remove_code, render_codes, select_all, select_item, selected_class, template_code;

  extend(ItemPicker, superClass);

  function ItemPicker() {
    this.search_items = bind(this.search_items, this);
    return ItemPicker.__super__.constructor.apply(this, arguments);
  }

  selected_class = "success";

  template_code = $(".code-container").html();

  $(".code-container").empty();

  current_popup = {};

  ItemPicker.prototype.init_popup = function(e) {
    var trigger_element;
    trigger_element = $(e.currentTarget);
    format_text_area(trigger_element);
    if (trigger_element.attr("data-mode")) {
      current_popup.mode = trigger_element.attr("data-mode");
    } else {
      current_popup.mode = "single";
    }
    if (trigger_element.attr("data-active-items")) {
      current_popup.active = trigger_element.attr("data-active-items");
    } else {
      current_popup.active = "all";
    }
    if (trigger_element.attr("data-relatedto") === "closest") {
      current_popup.target_element = trigger_element.siblings("input");
    } else if (trigger_element.attr("data-relatedto") === "parent closest") {
      current_popup.target_element = trigger_element.parent().siblings("input");
    } else if (trigger_element.attr("data-relatedto") === "customSampler") {
      current_popup.target_element = trigger_element.parent().parent().parent().find("textarea");
    } else {
      current_popup.target_element = $(trigger_element.attr("data-relatedto"));
    }
    current_popup.target_element.val(current_popup.target_element.val().replace(/\ /g, '').replace(/\s+/g, ''));
    if (current_popup.target_element.val() !== "") {
      current_popup.item_codes = current_popup.target_element.val().split(",");
    } else {
      current_popup.item_codes = [];
    }
    if (trigger_element.attr("data-option")) {
      current_popup.option = trigger_element.attr("data-option");
    } else {
      current_popup.option = "";
    }
    if (current_popup.option === "collection") {
      $("[name='allow_collection']").val("true");
      $("[name='allow_collection']").change();
      $("[name='allow_collection']").attr("disabled", "disabled");
      $(".back-button").hide();
    } else {
      this.display_item_picker_categories_all();
    }
    return render_codes();
  };

  format_text_area = function(trigger_element) {
    var target, text_area;
    target = trigger_element.attr("data-relatedto");
    text_area = $(".form-control" + target);
    if (text_area && text_area.val()) {
      return text_area.val(text_area.val().replace(/[\n\r]/g, ','));
    }
  };

  remove_code = function(e) {
    var code, element, index;
    element = $(e.currentTarget);
    code = element.html();
    index = current_popup.item_codes.indexOf(code);
    current_popup.item_codes.splice(index, 1);
    $(".item-picker.item[code='" + code + "']").removeClass(selected_class);
    return render_codes();
  };

  render_codes = function() {
    var code, html_codes, i, len, ref;
    $(".code-container").empty();
    html_codes = "";
    ref = current_popup.item_codes;
    for (i = 0, len = ref.length; i < len; i++) {
      code = ref[i];
      html_codes += template_code.replace("{code}", code);
    }
    $(".code-container").html(html_codes);
    return $(".code-item").on("click", remove_code);
  };

  ItemPicker.prototype.display_item_picker_categories_all = function() {
    var query;
    $("[name='category.name']").val("");
    $("#item-pick-modal .search").val("");
    $("#item-pick-modal .back-button").hide();
    $("#item-pick-modal #content").empty();
    $("[name='display.active']").val("true");
    query = JSON.stringify(get_query());
    return $.ajax({
      url: "/item-picker/search/" + query + "/" + current_popup.active,
      type: "GET",
      success: function(data) {
        $("#item-pick-modal #content").html(data);
        return $(".item-picker.category").on("click", display_item_picker_categories);
      }
    });
  };

  ItemPicker.prototype.search_items = function(e) {
    var element, query;
    if ($("#item-pick-modal").is(":hidden")) {
      return;
    }
    element = this.$(e.currentTarget);
    if (element.val() === "") {
      return this.display_item_picker_categories_all();
    }
    $("#item-pick-modal .back-button").show();
    query = JSON.stringify(get_query());
    $.ajax({
      url: "/item-picker/search/" + query + "/" + current_popup.active,
      type: "GET",
      success: function(data) {
        var code, i, len, ref;
        $("#item-pick-modal #content").html(data);
        if ((current_popup != null ? current_popup.item_codes : void 0) != null) {
          ref = current_popup.item_codes;
          for (i = 0, len = ref.length; i < len; i++) {
            code = ref[i];
            $(".item-picker.item[code='" + code + "']").addClass(selected_class);
          }
        }
        return $(".item-picker.item").on("click", select_item);
      }
    });
    if (current_popup.option === "collection") {
      return $(".back-button").hide();
    }
  };

  display_item_picker_categories = function(e) {
    var category, element;
    $("#item-pick-modal .back-button").show();
    $("#item-pick-modal #content").empty();
    element = $(e.currentTarget);
    category = element.attr("value");
    return $.ajax({
      url: "/item-picker/items/" + category + "/" + current_popup.active,
      type: "GET",
      success: function(data) {
        var code, i, len, ref;
        $("#item-pick-modal #content").html(data);
        ref = current_popup.item_codes;
        for (i = 0, len = ref.length; i < len; i++) {
          code = ref[i];
          $(".item-picker.item[code='" + code + "']").addClass(selected_class);
        }
        $(".item-picker.item").on("click", select_item);
        if (current_popup.mode === "multiple") {
          return $(".btn-select-all").on("click", select_all);
        } else {
          return $(".btn-select-all").remove();
        }
      }
    });
  };

  select_item = function(e) {
    var code, element, index;
    element = $(e.currentTarget);
    code = element.attr("code");
    if (element.hasClass(selected_class)) {
      element.removeClass(selected_class);
      index = current_popup.item_codes.indexOf(code);
      current_popup.item_codes.splice(index, 1);
    } else {
      if (current_popup.mode === "single") {
        $(".item-picker.item." + selected_class).removeClass(selected_class);
      }
      element.addClass(selected_class);
      if (current_popup.mode === "single") {
        current_popup.item_codes = [code];
      } else {
        current_popup.item_codes.push(code);
      }
    }
    return render_codes();
  };

  select_all = function(e) {
    var code, i, item_el, len, ref;
    ref = $(".item-picker.item");
    for (i = 0, len = ref.length; i < len; i++) {
      item_el = ref[i];
      if (!$(item_el).hasClass(selected_class)) {
        $(item_el).addClass(selected_class);
        code = $(item_el).attr("code");
        current_popup.item_codes.push(code);
      }
    }
    return render_codes();
  };

  ItemPicker.prototype.validate_changes = function(e) {
    return current_popup.target_element.val(current_popup.item_codes.join(",")).trigger("change");
  };

  ItemPicker.prototype.filter_categories = function(e) {
    var category, category_item, data_view, element, i, input_text, len, ref, results, text;
    element = $(e.currentTarget);
    if (element.val() === "") {
      return $(".item-picker.category").show();
    } else {
      data_view = document.body.getAttribute("data-view");
      if (data_view !== 'autoship_profile_c1') {
        input_text = element.val().toLowerCase();
        ref = $(".item-picker.category");
        results = [];
        for (i = 0, len = ref.length; i < len; i++) {
          category_item = ref[i];
          text = $(category_item).html();
          if (String(text).toLowerCase().indexOf(input_text) === -1) {
            results.push($(category_item).hide());
          } else {
            results.push($(category_item).show());
          }
        }
        return results;
      } else {
        $("#item-pick-modal .back-button").show();
        $("#item-pick-modal #content").empty();
        category = element.val();
        return $.ajax({
          url: "/item-picker/items/" + category + "/autoship_c1",
          type: "GET",
          success: function(data) {
            var code, j, len1, ref1;
            $("#item-pick-modal #content").html(data);
            ref1 = current_popup.item_codes;
            for (j = 0, len1 = ref1.length; j < len1; j++) {
              code = ref1[j];
              $(".item-picker.item[code='" + code + "']").addClass(selected_class);
            }
            $(".item-picker.item").on("click", select_item);
            if (current_popup.mode === "multiple") {
              return $(".btn-select-all").on("click", select_all);
            } else {
              return $(".btn-select-all").remove();
            }
          }
        });
      }
    }
  };

  ItemPicker.prototype.initialize = function() {
    $("#item-pick-modal").on("new-trigger", (function(_this) {
      return function() {
        $(".item-pick-trigger").off("click", _this.init_popup);
        return $(".item-pick-trigger").on("click", _this.init_popup);
      };
    })(this));
    $(".item-pick-trigger").on("click", this.init_popup);
    $(".validate-changes").on("click", this.validate_changes);
    $("[name='category.name']").on("keyup change", this.filter_categories);
    $(".back-button").on("click", this.display_item_picker_categories_all);
    $(".search").on("keyup change", this.search_items);
    return $("#item-pick-modal .search").on("keyup change", this.search_items);
  };

  get_query = function() {
    var query;
    query = {};
    $(".search").each(function() {
      if ($(this).val()) {
        return query[$(this).attr("name")] = $(this).val().replace(/\//g, '-');
      }
    });
    return query;
  };

  return ItemPicker;

})(Backbone.View);

module.exports = ItemPicker;
