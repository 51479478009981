var Order,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Order = (function(superClass) {
  extend(Order, superClass);

  function Order() {
    return Order.__super__.constructor.apply(this, arguments);
  }

  Order.prototype.cancel_order = function(e) {
    var order_id;
    order_id = $(e.currentTarget).attr("data-order-id");
    return $.ajax({
      url: '/cancel/order',
      data: {
        "order_id": order_id
      },
      type: 'POST',
      success: function(data) {
        return window.location.reload();
      },
      error: function(data) {
        console.debug("err", data);
        return window.location.reload();
      }
    });
  };

  Order.prototype.export_order = function(e) {
    var order_id, url;
    order_id = $("[name='order._id']").val();
    url = "/order/" + order_id + "/export";
    return $.ajax({
      url: url,
      type: "POST",
      success: function(data) {
        window.location.reload();
        return alert("Order exported");
      },
      error: function(data) {
        return console.debug("err", data);
      }
    });
  };

  Order.prototype.initialize = function() {
    return $(".js-cancel-order").on("click", this.cancel_order);
  };

  Order.prototype.events = {
    "click .js-export-order": "export_order"
  };

  return Order;

})(Backbone.View);

module.exports = Order;
