var AppMenuItem,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

AppMenuItem = (function(superClass) {
  extend(AppMenuItem, superClass);

  function AppMenuItem() {
    return AppMenuItem.__super__.constructor.apply(this, arguments);
  }

  AppMenuItem.prototype.initialize = function() {
    if ($("body").data('view') === "customer") {
      return $(".btn-clear-carts").click(function(e) {
        if (confirm("This will completely reset the cart for this user. Continue?")) {
          return $.ajax({
            url: window.location.href + "/clear-carts",
            method: "post",
            success: function() {
              return alert("Cart has been cleared");
            },
            error: function(e) {
              return alert((e != null ? e.message : void 0) || e || "Error");
            }
          });
        }
      });
    }
  };

  return AppMenuItem;

})(Backbone.View);

module.exports = AppMenuItem;
