var PageConfiguration,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

PageConfiguration = (function(superClass) {
  extend(PageConfiguration, superClass);

  function PageConfiguration() {
    return PageConfiguration.__super__.constructor.apply(this, arguments);
  }

  PageConfiguration.prototype.initialize = function() {
    $("table.resortable").tableDnD({
      onDrop: function(table, row) {
        var index;
        $("table.resortable").trigger("dndupdate");
        $("table.resortable>tbody tr").each((function(_this) {
          return function(i, e) {
            return $(e).find("[index]").val(i);
          };
        })(this));
        index = $(row).find("[index]");
        if (index.attr("index") === index.val()) {
          return $(row).attr("class", "");
        } else {
          return $(row).attr("class", "bg-warning");
        }
      },
      onDragStart: function(table, row) {
        return $(row).attr("class", "");
      },
      onDragClass: "bg-success"
    });
    return $("#save").on("click", this.clean_table);
  };

  PageConfiguration.prototype.clean_table = function() {
    return $("table.resortable>tbody tr").each((function(_this) {
      return function(i, e) {
        var index;
        index = $(e).find("[index]");
        index.attr("index", index.val());
        return $(e).attr("class", "");
      };
    })(this));
  };

  return PageConfiguration;

})(Backbone.View);

module.exports = PageConfiguration;
