var Orders,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Orders = (function(superClass) {
  extend(Orders, superClass);

  function Orders() {
    return Orders.__super__.constructor.apply(this, arguments);
  }

  Orders.prototype.toggle_canceled = function(e) {
    var order_id;
    console.info("Toggle Canceled");
    order_id = $(e.currentTarget).attr("data-order-id");
    return $.ajax({
      url: "/order/" + order_id + "/toggle_canceled",
      type: "GET",
      success: function(data) {
        return window.location.reload();
      },
      error: function(data) {
        console.debug("err", data);
        return window.location.reload();
      }
    });
  };

  Orders.prototype.toggle_pending = function(e) {
    var order_id;
    console.info("Toggle Pending");
    order_id = $(e.currentTarget).attr("data-order-id");
    return $.ajax({
      url: "/order/" + order_id + "/toggle_pending",
      type: "GET",
      success: function(data) {
        return window.location.reload();
      },
      error: function(data) {
        console.debug("err", data);
        return window.location.reload();
      }
    });
  };

  Orders.prototype.initialize = function() {
    console.log("initialize functions");
    $("body").on("click", ".js-toggle-canceled", this.toggle_canceled);
    $("body").on("click", ".js-toggle-pending", this.toggle_pending);
    return $(document).ajaxComplete(function() {
      $("body").on("click", ".js-toggle-pending", this.toggle_pending);
    });
  };

  return Orders;

})(Backbone.View);

module.exports = Orders;
