var AppLink,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

AppLink = (function(superClass) {
  var assocDomains, pathCalculations, protocols, reset_platform_fields, take_url;

  extend(AppLink, superClass);

  function AppLink() {
    return AppLink.__super__.constructor.apply(this, arguments);
  }

  take_url = function(parentEl) {
    var matches, url;
    url = parentEl.find(".appLink-url-edit").val();
    matches = url.match(/^[^\/]*\/(.*)/);
    url = (matches != null ? matches[1] : void 0) || '';
    return url;
  };

  pathCalculations = {
    'auctions-app': {
      'home': function() {
        return '';
      },
      'auction': take_url
    },
    'shop-app': {
      'home': function() {
        return '';
      },
      'item': take_url
    },
    'btd-app': {
      'home': function() {
        return '';
      }
    }
  };

  protocols = {
    'auctions-app': 'jrauctions',
    'shop-app': 'shopjr',
    'btd-app': 'beatthedealer'
  };

  assocDomains = {
    'auctions-app': 'https://auctions.cigars.com',
    'shop-app': 'https://www.cigars.com'
  };

  AppLink.prototype.recalculate_link = function(e) {
    var app, assocDomain, deepLink, imageNum, link, page, parentLi, path, protocol, ref, targetDeepLink, targetLink, targetLinkSelector;
    parentLi = $(e.currentTarget).parents('.app-link-container');
    imageNum = parentLi.attr('index');
    targetDeepLink = parentLi.find(".deep_link");
    app = parentLi.find('.appLink-app-edit').val();
    protocol = protocols[app];
    page = parentLi.find('.appLink-page-edit').val();
    path = ((ref = pathCalculations[app]) != null ? typeof ref[page] === "function" ? ref[page](parentLi) : void 0 : void 0) || '';
    deepLink = protocol + "://" + path;
    targetDeepLink.val(deepLink);
    targetLinkSelector = parentLi.find(".target_link").val();
    if ((targetLinkSelector != null) && targetLinkSelector !== "") {
      assocDomain = assocDomains[app];
      link = assocDomain + "/" + path;
      targetLinkSelector;
      targetLink = parentLi.find(targetLinkSelector);
      return targetLink.val(link);
    }
  };

  AppLink.prototype.show_platform_pages = function(e) {
    var dropdowns, key, platform, ref, ref1, val;
    platform = $(this).val();
    if (platform === "website") {
      $('#link-internal').hide();
    } else {
      $('#link-internal').show();
      dropdowns = {
        "shop-app": "#shop-app-pages",
        "auctions-app": "#auctions-app-pages",
        "btd-app": "#btd-app-pages"
      };
      for (key in dropdowns) {
        val = dropdowns[key];
        if ((ref = $(val)) != null) {
          ref.hide();
        }
      }
      if ((ref1 = $(dropdowns[platform])) != null) {
        ref1.show();
      }
    }
    return reset_platform_fields(platform);
  };

  reset_platform_fields = function(platform) {
    var field, fields, i, len, ref, results;
    if (platform !== "shop-app") {
      fields = [".item-picker"];
    } else {
      fields = [];
    }
    results = [];
    for (i = 0, len = fields.length; i < len; i++) {
      field = fields[i];
      results.push((ref = $(field)) != null ? ref.hide() : void 0);
    }
    return results;
  };

  AppLink.prototype.handle_link_select = function(e, page, changeField) {
    var defaultUrlVal, placeholder, ref, ref1, ref2, ref3, ref4, spacesRe, val;
    if (changeField == null) {
      changeField = true;
    }
    val = e ? $(this).val().toLowerCase() : page;
    if (changeField) {
      if ((ref = $('.link-url')) != null) {
        ref.val("");
      }
    }
    placeholder = "";
    if (val === "item") {
      if ((ref1 = $('.item-picker')) != null) {
        ref1.show();
      }
      placeholder = "Pick an item to get link";
    } else {
      if ((ref2 = $('.item-picker')) != null) {
        ref2.hide();
      }
    }
    if (val === "deals") {
      placeholder = "Paste the link for your deal here";
    }
    if (val === "brands") {
      placeholder = "Paste the link for your brand here";
    }
    if (val === "collections") {
      placeholder = "Paste the link for your collection here";
    }
    if (val === "manual") {
      placeholder = "Enter the desired link here manually";
    }
    if (placeholder === "") {
      spacesRe = new RegExp(' ', 'g');
      defaultUrlVal = "#" + val.replace(spacesRe, "-");
      if ((ref3 = $('.link-url')) != null) {
        ref3.val(defaultUrlVal);
      }
    }
    return (ref4 = $('.link-url')) != null ? ref4.attr("placeholder", placeholder) : void 0;
  };

  AppLink.prototype.update_internal_link = function(e) {
    var itemCode, route;
    itemCode = $(this).val();
    route = "#item/code/" + itemCode;
    return $('.link-url').val(route);
  };

  initialize(function() {
    $('.appLink-url-edit').on('input', this.recalculate_link);
    $('.appLink-app-edit').on('input', this.recalculate_link);
    $('.appLink-page-edit').on('input', this.recalculate_link);
    $('.platform-select').on('change', this.show_platform_pages);
    $('.link-select').on('change', this.handle_link_select);
    return $('.item-to-link').on('change', this.update_internal_link);
  });

  return AppLink;

})(Backbone.View);

module.exports = AppLink;
