var OrderExports,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

module.exports = OrderExports = (function(superClass) {
  extend(OrderExports, superClass);

  function OrderExports() {
    return OrderExports.__super__.constructor.apply(this, arguments);
  }

  OrderExports.prototype.events = {
    "click .js-new-order-export": "new_order_export"
  };

  OrderExports.prototype.new_order_export = function(e) {
    return $.ajax({
      url: "/order_export/create",
      type: "POST",
      success: function(data) {
        var order_export;
        order_export = data.order_export;
        if (order_export) {
          return window.location = "/order_export/" + order_export;
        } else {
          return alert("No pending orders to export.");
        }
      },
      error: function(err) {
        console.error(err);
        return alert("Order export unsuccessful");
      }
    });
  };

  return OrderExports;

})(Backbone.View);
