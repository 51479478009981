var PlannedContent,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

PlannedContent = (function(superClass) {
  extend(PlannedContent, superClass);

  function PlannedContent() {
    return PlannedContent.__super__.constructor.apply(this, arguments);
  }

  PlannedContent.prototype.select_content_level = function() {
    var level;
    level = $("[name='planned_content.level']").val();
    switch (level) {
      case "home_page_block":
        $("#planned_content_image").show();
        $("#planned_content_action_type").show();
        $("#planned_content_action_text").show();
        return $("#planned_content_action_url").show();
      default:
        $("#planned_content_image").hide();
        $("#planned_content_action_type").hide();
        $("#planned_content_action_text").hide();
        return $("#planned_content_action_url").hide();
    }
  };

  PlannedContent.prototype.initialize = function() {
    $("[name='planned_content.level']").on("change", this.select_content_level);
    return this.select_content_level();
  };

  return PlannedContent;

})(Backbone.View);

module.exports = PlannedContent;
