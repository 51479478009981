var SearchPage,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

SearchPage = (function(superClass) {
  var buildSearchUrl, dataToFoURL, toggleDisplayOptions;

  extend(SearchPage, superClass);

  function SearchPage() {
    return SearchPage.__super__.constructor.apply(this, arguments);
  }

  dataToFoURL = function(data) {
    var key, url, val;
    url = "/all-auctions?page=1&results_per_page=20";
    for (key in data) {
      val = data[key];
      if ((val != null) && val !== '') {
        if (key === "brand" || key === "type" || key === "packaging") {
          url += "&" + key + "-" + (val.replace(/\s/g, '+')) + "=on";
        } else {
          url += "&" + key + "=" + val;
        }
      }
    }
    return url;
  };

  buildSearchUrl = function() {
    var data;
    data = {};
    $(".auction-url").each(function() {
      return data[$(this).attr('name')] = $(this).val();
    });
    if (data.type === "all") {
      delete data.type;
    }
    return $("[name='search_page.url']").val(dataToFoURL(data));
  };

  toggleDisplayOptions = function(webiste) {
    var $cigars;
    $cigars = $("#cigars-options");
    switch (webiste) {
      case "cigars.com":
        $cigars.show();
        return $auctions.hide();
    }
  };

  $(".website-select").on("change", function(e) {
    return toggleDisplayOptions(e.currentTarget.value);
  });

  $(".auction-url").on("change", buildSearchUrl);

  return SearchPage;

})(Backbone.View);

module.exports = SearchPage;
