var OrderExport,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

module.exports = OrderExport = (function(superClass) {
  extend(OrderExport, superClass);

  function OrderExport() {
    return OrderExport.__super__.constructor.apply(this, arguments);
  }

  OrderExport.prototype.events = {
    "click .js-copy-to-clipboard": "copy_to_clipboard"
  };

  OrderExport.prototype.copy_to_clipboard = function(e) {
    var err, msg, successful;
    $(e.currentTarget.dataset.target).select();
    try {
      successful = document.execCommand("copy");
      msg = successful ? "successful" : "unsuccessful";
      return alert("Copying text was " + msg);
    } catch (error) {
      err = error;
      return alert("Oops, unable to copy");
    }
  };

  return OrderExport;

})(Backbone.View);
