var ScheduleNotification,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

ScheduleNotification = (function(superClass) {
  extend(ScheduleNotification, superClass);

  function ScheduleNotification() {
    return ScheduleNotification.__super__.constructor.apply(this, arguments);
  }

  ScheduleNotification.prototype.initialize = function() {
    $('#save').on('click', (function(_this) {
      return function(e) {
        var form;
        if (_this.validate()) {
          $('#confirmation-modal').show();
          $('#confirmation-information').empty();
          form = $('form').serializeArray().slice(1);
          $.each(form, function(index, value) {
            if (value.value.length) {
              if (value.name.split('.')[1] === 'territories') {
                if ($('.territories').length) {
                  $('.territories').after($('option[value="' + value.value + '"]').eq(0).text() + ', ');
                } else {
                  $('#confirmation-information').append('<p>' + '<b class="territories">' + value.name.split('.')[1] + ': </b>' + $('option[value="' + value.value + '"]').eq(0).text() + '</p>');
                }
              } else if (value.name.split('.')[1] === 'user_groups') {
                if ($('.user_groups').length) {
                  $('.user_groups').after($('option[value="' + value.value + '"]').eq(0).text() + ', ');
                } else {
                  $('#confirmation-information').append('<p>' + '<b class="user_groups">User Groups: </b>' + $('option[value="' + value.value + '"]').eq(0).text() + '</p>');
                }
              } else {
                $('#confirmation-information').append('<p>' + '<b>' + value.name.split('.')[1] + '</b>' + ': ' + value.value + '</p>');
              }
            }
          });
        }
      };
    })(this));
    $('#submit').on('click', (function(_this) {
      return function(e) {
        $('form').trigger('submit');
        $('#confirmation-modal').hide();
      };
    })(this));
    return $('#back').on('click', (function(_this) {
      return function(e) {
        $('#confirmation-modal').hide();
      };
    })(this));
  };

  ScheduleNotification.prototype.validate = function() {
    var action, deal, internals_input, message, redirect, required, required_els, required_vals, t360_input, territory_input, user_group_input, valid;
    valid = true;
    message = "";
    required_els = $("[required]");
    required_vals = _.compact($("[required]").map(function(i) {
      return $(this).val();
    }));
    required = required_els.length === required_vals.length;
    if (!required) {
      message += "<br />Please fill in all require fields with *";
      valid = false;
    }
    redirect = $("[name='schedule_notification.action']");
    deal = $("[name='schedule_notification.deal']");
    if (redirect.val() !== "" && deal.val() !== "") {
      message += "<br />Please leave redirect empty when selecting a deal.";
      valid = false;
    }
    t360_input = $("[name='schedule_notification.t360_membership']");
    territory_input = $("[name='schedule_notification.territories']");
    user_group_input = $("[name='schedule_notification.user_groups']");
    internals_input = $("[name='schedule_notification.internals']");
    action = t360_input.prop("checked") || !!territory_input.val() || !!user_group_input.val() || !!internals_input.val();
    if (!action) {
      message += "<br />You must select at least one target to send notifications to.";
      valid = false;
    }
    $(".alert").toggleClass("hide", valid);
    $(".alert-body").html(message);
    if (!valid) {
      window.scrollTo(0, 0);
    }
    return valid;
  };

  return ScheduleNotification;

})(Backbone.View);

module.exports = ScheduleNotification;
