var Item,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Item = (function(superClass) {
  extend(Item, superClass);

  function Item() {
    return Item.__super__.constructor.apply(this, arguments);
  }

  Item.prototype.do_price_update = function(e) {
    var item_id;
    e.preventDefault();
    item_id = $("input[name='item._id']").val();
    return $.ajax({
      async: false,
      type: "POST",
      url: "/price/update",
      data: {
        item_id: item_id
      },
      success: function(r) {
        alert('The price has been updated.');
        return window.location.reload();
      },
      error: function(e) {
        return alert(e.statusText);
      }
    });
  };

  Item.prototype.initialize = function() {
    return $(".btn-price-update").on("click", this.do_price_update);
  };

  return Item;

})(Backbone.View);

module.exports = Item;
