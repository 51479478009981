var Deal,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Deal = (function(superClass) {
  extend(Deal, superClass);

  function Deal() {
    return Deal.__super__.constructor.apply(this, arguments);
  }

  Deal.prototype.select_is_global = function() {
    if ($("[name='deal.configuration.is_global']").is(":checked")) {
      $("#trigger-items").hide();
      $("#excluded-items").show();
    } else {
      $("#trigger-items").show();
      $("#excluded-items").hide();
    }
    if ($("[name='deal.presentation.is_global']").is(":checked")) {
      $("#page-trigger-item").hide();
      return $("#page-excluded-items").show();
    } else {
      $("#page-trigger-item").show();
      return $("#page-excluded-items").hide();
    }
  };

  Deal.prototype.select_deal_type = function() {
    var deal_type;
    deal_type = $("[name='deal.configuration.deal_type']").val();
    $("#mandatory-items").hide();
    $("#page-mandatory-items").hide();
    if (deal_type === "is_bonus_buy") {
      $(".trigger-category").hide();
      $("#is_global").show();
      $("#deal-limit").hide();
      $("[name='deal.configuration.limit']").val(0);
      $("#cart-amount").show();
      $("#coupon-code").hide();
      $("#trigger-popup").hide();
      $("[name='deal.configuration.trigger_popup']").prop("checked", true);
      $(".rules").show();
      $(".add-rule-btn").show();
      $(".rule-price").hide();
      $(".rule-price input").val(0);
      $(".coupon-percent").hide();
      return $(".coupon-prefix").hide();
    } else if (deal_type === "is_dynamic_bonus_buy") {
      $(".trigger-category").show();
      $("#is_global").hide();
      $("#deal-limit").hide();
      $("[name='deal.configuration.limit']").val(0);
      $("#cart-amount").show();
      $("#coupon-code").hide();
      $("#trigger-popup").hide();
      $("[name='deal.configuration.trigger_popup']").prop("checked", true);
      $(".rules").show();
      $(".add-rule-btn").show();
      $(".rule-price").hide();
      $(".rule-price input").val(0);
      $(".coupon-percent").hide();
      return $(".coupon-prefix").hide();
    } else if (deal_type === "is_modal_popup") {
      $(".trigger-category").hide();
      $("#is_global").show();
      $("#deal-limit").show();
      $("#cart-amount").show();
      $("#coupon-code").hide();
      $("#trigger-popup").show();
      $("[name='deal.configuration.trigger_popup']").prop("checked", true);
      $(".rules").show();
      $(".add-rule-btn").show();
      $(".rule-price").hide();
      $(".rule-price input").val(0);
      $(".coupon-percent").hide();
      return $(".coupon-prefix").hide();
    } else if (deal_type === "is_auto_add") {
      $(".trigger-category").hide();
      $("#deal-limit").show();
      $("#cart-amount").hide();
      $("#coupon-code").hide();
      $("#trigger-popup").hide();
      $(".rules").show();
      $(".add-rule-btn").show();
      $(".rule-price").hide();
      $(".rule-price input").val(0);
      $(".coupon-percent").hide();
      return $(".coupon-prefix").hide();
    } else if (deal_type === "is_auto_add_promo_code") {
      $(".trigger-category").hide();
      $("#deal-limit").hide();
      $("[name='deal.configuration.limit']").val(1);
      $("#cart-amount").show();
      $(".rule-price").show();
      $("#coupon-code").show();
      $("#trigger-popup").hide();
      $(".rules").hide();
      $(".add-rule-btn").hide();
      $(".coupon-percent").hide();
      return $(".coupon-prefix").hide();
    } else if (deal_type === "is_mix_match") {
      $(".trigger-category").hide();
      $("#deal-limit").show();
      $("#cart-amount").hide();
      $(".rule-price").show();
      $("#coupon-code").hide();
      $("#mix-match-select").show();
      $("#trigger-popup").show();
      $(".rules").show();
      $(".add-rule-btn").show();
      $(".rule-price").hide();
      $(".rule-price input").val(0);
      $(".coupon-percent").hide();
      $(".coupon-prefix").hide();
      $("#mandatory-items").show();
      return $("#page-mandatory-items").show();
    } else if (deal_type === 'send_coupons') {
      $(".trigger-category").hide();
      $("#coupon-info").show();
      return $("#cart-amount").hide();
    } else {
      $(".trigger-category").hide();
      $("#deal-limit").hide();
      $("#cart-amount").hide();
      $(".rule-price").show();
      $("#coupon-code").hide();
      $("#trigger-popup").hide();
      $(".rules").hide();
      $(".add-rule-btn").hide();
      $(".coupon-percent").hide();
      return $(".coupon-prefix").hide();
    }
  };

  Deal.prototype.fill_items_by_category = function() {
    return console.log($("[name='deal.configuration.category']").val());
  };

  Deal.prototype.initialize = function() {
    $("[name='deal.configuration.is_global']").on("change", this.select_is_global);
    $("[name='deal.presentation.is_global']").on("change", this.select_is_global);
    this.select_is_global();
    $(document).on('click', '[data-index]', function(e) {
      return $(e.currentTarget).closest('[data-deal]').remove();
    });
    $(document).on('change', '.itemcodes', function() {
      var value;
      value = $(this).val().replace(/\s+/g, ',');
      return $(this).val(value);
    });
    $("[name='deal.configuration.enabled']").on("change", function(e) {
      if ($(e.currentTarget).is(":checked")) {
        return $("[name='deal.configuration.quantity']").attr("required", "required");
      } else {
        return $("[name='deal.configuration.quantity']").removeAttr("required");
      }
    });
    $("[name='deal.configuration.deal_type']").on("change", this.select_deal_type);
    this.select_deal_type();
    $("[name='deal.configuration.category']").on("change", this.fill_items_by_category);
    this.fill_items_by_category();
    if ($("[name='deal.presentation.enabled']").is(":checked")) {
      $("[data-parent='#presentation-panel']").trigger("click");
    }
    if ($("[name='deal.configuration.enabled']").is(":checked")) {
      return $("[data-parent='#configuration-panel']").trigger("click");
    }
  };

  return Deal;

})(Backbone.View);

module.exports = Deal;
