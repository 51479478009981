var GenericTableView, datetimepicker_workaround,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

datetimepicker_workaround = function($el) {
  var $input, keys;
  $input = $el;
  if (!$el.is('input')) {
    $input = $el.find('input');
  }
  $input.on('focus', function() {
    var e;
    e = $.Event('keyup');
    e.which = 9;
    return $input.trigger(e);
  });
  keys = $el.data('DateTimePicker').options().keyBinds;
  keys['enter'] = function() {
    var e;
    this.hide();
    e = $.Event('keyup');
    e.which = 13;
    $input.trigger(e);
    if (!$el.is('input') && !this.options.allowInputToggle) {
      return $input.focus();
    }
  };
  return $el.data("DateTimePicker").keyBinds(keys);
};

GenericTableView = (function(superClass) {
  var is_sorted_on;

  extend(GenericTableView, superClass);

  function GenericTableView() {
    this.refresh_items_list = bind(this.refresh_items_list, this);
    this.remove_click = bind(this.remove_click, this);
    this.update_click = bind(this.update_click, this);
    return GenericTableView.__super__.constructor.apply(this, arguments);
  }

  is_sorted_on = {};

  GenericTableView.prototype.events = {
    'click .col-sort': 'sort_click'
  };

  GenericTableView.prototype.initialize = function() {
    var scope, type;
    console.log("init the generic table");
    type = $("[data-table]").data("table");
    $("#clear-filter").on("click", function() {
      return $("#filter-collapse").find("input, select").val("").trigger("change");
    });
    $("[data-default-filter]").on("click", function() {
      var filter, infos, j, len, prop, props;
      $("[data-default-filter]").removeClass("active");
      $(this).addClass("active");
      $("#filter-accordion input,select").each(function() {
        return $(this).val("");
      });
      filter = $(this).data("default-filter");
      props = filter.split(/\&/);
      for (j = 0, len = props.length; j < len; j++) {
        prop = props[j];
        infos = prop.split(/\=/);
        $("[name='" + infos[0] + "']").val(infos[1]);
      }
      return this.refresh_items_list();
    });
    $(".is_a_date").each(function() {
      $(this).datetimepicker({
        format: "MM/DD/YYYY",
        timeZone: 'America/New_York'
      });
      return datetimepicker_workaround($(this));
    });
    $(".eft_date").each(function() {
      var now, tomorrow;
      now = new Date();
      tomorrow = now.setDate(new Date().getDate() + 1);
      $(this).datetimepicker({
        format: "MM/DD/YYYY",
        timeZone: 'America/New_York',
        defaultDate: tomorrow
      });
      return datetimepicker_workaround($(this));
    });
    $(".is_a_datetime").each(function() {
      $(this).datetimepicker();
      return datetimepicker_workaround($(this));
    });
    $(".search").on("keyup change", this.refresh_items_list);
    $("#update").on("click", this.update_click);
    $("table.resortable").tableDnD({
      onDrop: function(table, rows) {
        var level, new_order, order, url_reorder;
        new_order = [];
        level = 1;
        order = 0;
        $("table.resortable").trigger("dndupdate");
        $("table.resortable>tbody tr").each((function(_this) {
          return function(i, e) {
            new_order.push({
              id: $(e).attr("id"),
              position: order
            });
            return order++;
          };
        })(this));
        url_reorder = $("table.resortable").data("url-reorder");
        return $.ajax({
          url: url_reorder,
          type: "POST",
          data: {
            order: new_order
          },
          success: function() {
            return console.log("SUCCESS");
          },
          error: function() {
            return console.log("ERROR");
          }
        });
      }
    });
    $(".remove").on("click", this.remove_click);
    this.singular = type;
    this.plural = type === "category" ? "categories" : type + "s";
    this.current_ajax_search_call = null;
    scope = this;
    return $("table." + this.plural + ">tbody").waypoint('infinite', {
      container: "auto",
      items: "table." + this.plural + ">tbody tr",
      more: "a.view_more",
      offset: 'bottom-in-view',
      onBeforePageLoad: function() {
        return scope.show_loader();
      },
      onAfterPageLoad: function() {
        return scope.hide_loader();
      }
    });
  };

  GenericTableView.prototype.sort_click = function(e) {
    var $el, column;
    $el = $(e.currentTarget);
    column = $el.data("column");
    if (column) {
      $el.removeClass("sort-asc sort-desc").siblings().removeClass("sort-asc sort-desc");
      if (is_sorted_on[column]) {
        if (is_sorted_on[column] === "asc") {
          is_sorted_on[column] = "desc";
        } else {
          is_sorted_on[column] = "asc";
        }
      } else {
        is_sorted_on = {};
        is_sorted_on[column] = "asc";
      }
      $el.addClass("sort-" + is_sorted_on[column]);
    }
    return this.refresh_items_list();
  };

  GenericTableView.prototype.update_click = function(e) {
    var ids, values;
    e.preventDefault();
    values = {};
    $.each($('#groupUpdate form').serializeArray(), function(i, field) {
      return values[field.name] = field.value;
    });
    if (values[this.singular + ".detail.name"]) {
      values[(this.singular + ".details.") + values[this.singular + ".detail.name"]] = values[this.singular + ".detail.value"];
      delete values[this.singular + ".detail.name"];
      delete values[this.singular + ".detail.value"];
    }
    ids = [];
    $("input.group-update:checked").each(function() {
      return ids.push($(this).attr("data-id").toString());
    });
    values.ids = ids;
    this.show_loader();
    return $.ajax({
      type: "POST",
      url: $("#groupUpdate form").attr("action"),
      data: values,
      success: (function(_this) {
        return function() {
          _this.hide_loader();
          $("#groupUpdate").modal("hide");
          return _this.refresh_items_list();
        };
      })(this)
    });
  };

  $("[data-target='#groupUpdate'").toggleClass("disabled", true);

  $(".select-all-or-none").on("change", function() {
    var bool;
    bool = $(this).prop("checked");
    $("input.group-update").prop("checked", bool);
    return $("[data-target='#groupUpdate'").toggleClass("disabled", !bool);
  });

  $("input.group-update").on("change", GenericTableView.group_update_change);

  $(".is_a_date").each(function() {
    return $(this).datetimepicker({
      format: "MM/DD/YYYY",
      timeZone: 'America/New_York'
    });
  });

  $(".is_a_datetime").each(function() {
    return $(this).datetimepicker();
  });

  GenericTableView.prototype.remove_click = function(e) {
    var confirmation, row, url;
    e.preventDefault();
    url = $(e.currentTarget).attr("href");
    confirmation = $(e.currentTarget).attr("confirmation");
    row = $(e.currentTarget).parents("tr");
    this.show_loader();
    if (window.confirm(confirmation)) {
      return $.ajax({
        type: "POST",
        url: url,
        success: (function(_this) {
          return function() {
            _this.hide_loader();
            return row.remove();
          };
        })(this),
        error: (function(_this) {
          return function() {
            _this.hide_loader();
            return e.target.blur();
          };
        })(this)
      });
    } else {
      this.hide_loader();
      return e.target.blur();
    }
  };

  GenericTableView.prototype.show_loader = function() {
    return $("#loading").removeClass("invisible");
  };

  GenericTableView.prototype.hide_loader = function() {
    return $("#loading").addClass("invisible");
  };

  GenericTableView.prototype.group_update_change = function(e) {
    return $("[data-target='#groupUpdate'").toggleClass("disabled", $("input.group-update:checked").size() === 0);
  };

  GenericTableView.prototype.refresh_items_list = function() {
    var query, ref, sort, url;
    query = JSON.stringify(this.get_query());
    sort = JSON.stringify(is_sorted_on);
    url = "/" + this.plural + "/0/20/" + query + "/" + sort;
    if ((ref = this.current_ajax_search_call) != null) {
      if (typeof ref.abort === "function") {
        ref.abort();
      }
    }
    this.show_loader();
    return this.current_ajax_search_call = $.ajax({
      type: "GET",
      url: url,
      success: (function(_this) {
        return function(res) {
          var content, page, view_more;
          _this.hide_loader();
          page = $(res);
          content = page.find("table." + _this.plural + ">tbody tr");
          view_more = page.find("a.view_more");
          $("table." + _this.plural + ">tbody").html(content);
          $(".select-all-or-none").prop("checked", false);
          $(".select-all-or-none").trigger("change");
          if ($("table").is(".resortable")) {
            $("table.resortable").tableDnDUpdate();
          }
          if (view_more.length > 0) {
            $("a.view_more").attr('href', view_more.attr('href')).show();
            $.waypoints('refresh');
          } else {
            $("a.view_more").hide();
          }
          $("input.group-update").on("change", _this.group_update_change);
          return $(".remove").on("click", _this.remove_click);
        };
      })(this),
      error: (function(_this) {
        return function() {
          return _this.hide_loader();
        };
      })(this)
    });
  };

  GenericTableView.prototype.get_query = function() {
    var query;
    query = {};
    $(".search").each(function() {
      if ($(this).attr("search_type")) {
        if ($(this).val()) {
          query[$(this).attr("name")] = {};
          return query[$(this).attr("name")][$(this).attr("search_type")] = $(this).val().replace(/\//g, '-');
        }
      } else {
        if ($(this).val()) {
          return query[$(this).attr("name")] = $(this).val().replace(/\//g, '-');
        }
      }
    });
    return query;
  };

  return GenericTableView;

})(Backbone.View);

module.exports = GenericTableView;
