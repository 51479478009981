var GenericFormView,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

GenericFormView = (function(superClass) {
  var data_add, sendFile, update_form_with_richtext;

  extend(GenericFormView, superClass);

  function GenericFormView() {
    return GenericFormView.__super__.constructor.apply(this, arguments);
  }

  GenericFormView.prototype.initialize = function() {
    var images_to_remove;
    $(".select_program_dropdown").on("change", function(e) {
      var program_name;
      program_name = $(e.currentTarget).find(":selected").html();
      return $(".selected_program_name").val(program_name);
    });
    $(document).on("click", "[data-add]", data_add);
    $(".autocomplete-dropdown").each(function() {
      return $(this).selectize();
    });
    $(".selectpicker").each(function() {
      return $(this).selectpicker();
    });
    $("[data-type='richtext']").each(function(e) {
      var elt, extraOption, toolbarOption;
      elt = $(this);
      toolbarOption = [['style', ['bold', 'italic', 'underline', 'clear']], ['para', ['ul', 'ol', 'paragraph']], ['insert', ['picture', 'link', 'hr']], ['view', ['fullscreen', 'codeview']]];
      extraOption = elt.data('option');
      if (extraOption) {
        toolbarOption.push(extraOption);
      }
      return elt.summernote({
        toolbar: toolbarOption,
        onpaste: (function(_this) {
          return function(e) {
            var bufferText;
            bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
            e.preventDefault();
            return setTimeout(function() {
              return document.execCommand('insertText', false, bufferText);
            }, 10);
          };
        })(this),
        onkeyup: (function(_this) {
          return function(e) {
            return update_form_with_richtext(e);
          };
        })(this),
        onImageUpload: (function(_this) {
          return function(files, editor, welEditable) {
            console.log("onImageUpload files, editor, welEditable", files, editor, welEditable);
            return sendFile(files[0], function(url) {
              editor.insertImage(welEditable, url);
              return update_form_with_richtext(e);
            });
          };
        })(this)
      });
    });
    $("form select, form input, form textarea").on("change", function() {
      $("#save").removeClass("btn-success").addClass("btn-primary").html("Save");
      return $(".btn-details-new").attr("confirmation", "Are you sure you want to leave this page without saving your changes?");
    });
    images_to_remove = [];
    $("[data-action='remove-image']").on("click", function() {
      var data;
      data = $(this).data();
      images_to_remove.push(data);
      return $(this).parents("li").remove();
    });
    $("input.file.single-image").on("fileclear", function() {
      return images_to_remove.push({
        name: $(this).attr("name"),
        image: $(this).data("image"),
        action: "remove-image"
      });
    });
    $("form").on("submit", function(e) {
      var already_sent, data, image_processed, isArray, nb_images, nb_images_processed, obj_prefix, readers, render_gallery, valid, values;
      e.preventDefault();
      $("[data-type='richtext']").each(function() {
        var elt;
        elt = $(this);
        return update_form_with_richtext(elt);
      });
      $(".template.hide").remove();
      data = $("form").serializeArray();
      $("form input[type=checkbox]:not(:checked):visible").each(function() {
        return data.push({
          "name": this.name,
          "value": "off"
        });
      });
      values = {};
      obj_prefix = $("body").data("view");
      isArray = Array.isArray || function(obj) {
        return toString.call(obj) === "[object Array]";
      };
      $.each(data, function(i, field) {
        if (values[field.name] != null) {
          if (!isArray(values[field.name])) {
            values[field.name] = [values[field.name]];
          }
          return values[field.name].push(field.value);
        } else {
          return values[field.name] = field.value;
        }
      });
      values[obj_prefix + ".images_to_remove"] = images_to_remove;
      nb_images = 0;
      valid = true;
      $("form").find("[type='file']").each(function() {
        var ref, ref1;
        nb_images += this.files.length;
        if ($(this).data("is-video") === true && ((ref = $(this)[0]) != null ? (ref1 = ref.files[0]) != null ? ref1.size : void 0 : void 0) / 1024 / 1024 > 2) {
          alert("Video too big. Please upload a video less than 2MB");
          return valid = false;
        }
      });
      if (!valid) {
        return valid;
      }
      render_gallery = function(gallery, gallery_captions) {
        var caption, caption_name, gallery_name, html, i, image, j, len;
        $("ul.list-group.gallery").empty();
        html = "";
        caption_name = $("ul.list-group.gallery").attr("data-caption-name");
        gallery_name = $("ul.list-group.gallery").attr("data-gallery-name");
        for (i = j = 0, len = gallery.length; j < len; i = ++j) {
          image = gallery[i];
          caption = gallery_captions[i] ? gallery_captions[i] : "";
          html += '<li class="list-group-item"><img src="/0x100/images/' + image + '" height="100"/> <button data-name="' + gallery_name + '" data-action="remove-image" data-image="' + image + '" class="btn btn-warning">Remove this image</button> <input type="text" name="' + caption_name + '" value="' + caption + '"/> </li>';
        }
        $("ul.list-group.gallery").html(html);
        $("ul.list-group.gallery").parents(".form-group").find(".fileinput-remove").click();
        return $("[data-action='remove-image']").on("click", function() {
          data = $(this).data();
          images_to_remove.push(data);
          return $(this).parents("li").remove();
        });
      };
      already_sent = false;
      nb_images_processed = -1;
      image_processed = function() {
        nb_images_processed += 1;
        if (nb_images_processed >= nb_images && !already_sent) {
          already_sent = true;
          $("#save").removeClass("btn-success").addClass("btn-primary").html("<img src='/img/loader-dark.gif' width=16>&nbsp;Saving").blur().attr('disabled', 'true');
          return $.ajax({
            type: "POST",
            url: $("form").attr("action"),
            data: values,
            success: function(data) {
              $("#loading").show();
              window.setTimeout((function() {
                return $("#loading").hide();
              }), 600);
              $(".btn-details-new").removeAttr("confirmation");
              $("#id").val(data._id).trigger("saved", data);
              if (data.gallery && data.gallery_captions) {
                render_gallery(data.gallery, data.gallery_captions);
              }
              $("form").trigger("saved", data);
              return $("#save").removeClass("btn-primary").addClass("btn-success").html("<span class='glyphicon glyphicon-ok' />&nbsp;Saved").removeAttr('disabled');
            },
            error: function(e) {
              $("#save").html("Save").removeAttr('disabled');
              if (e.responseText) {
                alert(e.responseText);
              }
              if (e.status === 401) {
                return document.location.href = "/";
              }
            }
          });
        }
      };
      readers = [];
      $("form").find("[type='file']").each(function() {
        var file, j, len, my_file, name, ref, results;
        name = $(this).attr("name");
        ref = this.files;
        results = [];
        for (j = 0, len = ref.length; j < len; j++) {
          file = ref[j];
          my_file = file;
          results.push((function(my_file) {
            var reader;
            if (my_file) {
              reader = new FileReader();
              readers.push(reader);
              reader.onload = function(event) {
                if (!values[name]) {
                  values[name] = [];
                  $("[data-name='" + name + "']").each(function(i, e) {
                    console.log("e", $(e).attr("data-image"));
                    return values[name].push($(e).attr("data-image"));
                  });
                }
                if (values[name] == null) {
                  values[name] = [];
                }
                values[name].push(reader.result);
                return image_processed();
              };
              return reader.readAsDataURL(my_file);
            } else {
              return image_processed();
            }
          })(my_file));
        }
        return results;
      });
      return image_processed();
    });
    $(".is_a_date.planning-start").on("change", function(e) {
      var element, planning_end;
      element = $(e.currentTarget);
      planning_end = element.parents(".row").find(".planning-end");
      planning_end.data('DateTimePicker').setMinDate(element.val());
      return true;
    });
    $(".is_a_date.planning-end").on("change", function(e) {
      var element, planning_start;
      element = $(e.currentTarget);
      planning_start = element.parents(".row").find(".planning-start");
      planning_start.data('DateTimePicker').setMaxDate(element.val());
      return true;
    });
    return $(".is_a_date").each(function() {
      var options;
      options = {
        format: "YYYY/MM/DD h:mm a",
        timeZone: 'America/New_York'
      };
      if ($(this).attr("startdate")) {
        options.minDate = new Date(new Number($(this).attr("startdate")));
      }
      if ($(this).attr("enddate")) {
        options.maxDate = new Date(new Number($(this).attr("enddate")));
      }
      return $(this).datetimepicker(options);
    });
  };

  $(".eft_date").each(function() {
    var options;
    options = {
      format: "YYYY/MM/DD h:mm a",
      timeZone: 'America/New_York',
      defaultDate: new Date()
    };
    if ($(this).attr("startdate")) {
      options.minDate = new Date(new Number($(this).attr("startdate")));
    }
    if ($(this).attr("enddate")) {
      options.maxDate = new Date(new Number($(this).attr("enddate")));
    }
    return $(this).datetimepicker(options);
  });

  $(".is_a_week").each(function() {
    var options;
    options = {
      calendarWeeks: true,
      pickWeek: true,
      format: "YYYY/MM/DD",
      daysOfWeekDisabled: [1, 2, 3, 4, 5, 6]
    };
    if ($(this).attr("startdate")) {
      options.minDate = new Date(new Number($(this).attr("startdate")));
    }
    if ($(this).attr("enddate")) {
      options.maxDate = new Date(new Number($(this).attr("enddate")));
    }
    return $(this).datetimepicker(options);
  });

  $(".is_a_datetime").each(function() {
    var options;
    options = {
      format: "YYYY/MM/DD h:mm a z",
      timeZone: $("#timezone").data("tz")
    };
    if ($(this).attr("startdate")) {
      options.minDate = new Date(new Number($(this).attr("startdate")));
    }
    if ($(this).attr("enddate")) {
      options.maxDate = new Date(new Number($(this).attr("enddate")));
    }
    return $(this).datetimepicker(options);
  });

  $(".remove").on("click", function(e) {
    var confirmation, row, url;
    e.preventDefault();
    url = $(this).attr("href");
    confirmation = $(this).attr("confirmation");
    row = $(this).parents("tr");
    if (window.confirm(confirmation)) {
      return $.ajax({
        type: "POST",
        url: url,
        success: function() {
          return row.remove();
        }
      });
    }
  });

  $(".btn-details-new").on("click", function(e) {
    var confirmation;
    confirmation = $(this).attr("confirmation");
    if (!((!confirmation) || window.confirm(confirmation))) {
      e.preventDefault();
      return false;
    }
  });

  $(document).on("click", "[data-required-if]", function(e) {
    var target;
    target = $($(e.currentTarget).attr("data-required-if"));
    if ($(e.currentTarget).is(":checked")) {
      return target.attr("required", true);
    } else {
      return target.removeAttr("required");
    }
  });

  $(document).on("click", "[data-remove]", function(e) {
    e.preventDefault();
    $(e.currentTarget).parents($(e.currentTarget).data("remove")).remove();
    $(document).trigger("data-removed");
    $(document).off("click", "[data-add]", data_add);
    return $(document).on("click", "[data-add]", data_add);
  });

  $("[data-fill]").on("change", function() {
    var $fill, dict, dict_name, fill_name, select, val;
    val = $(this).val();
    fill_name = $(this).data("fill");
    dict_name = $(this).data("fill-dict");
    $fill = $("[name='" + fill_name + "']");
    dict = window[dict_name];
    if ((dict != null) && (dict[val] != null)) {
      select = $fill[0].selectize;
      if (select) {
        return select.setValue(dict[val]);
      } else {
        return $fill.val(dict[val]);
      }
    }
  });

  $("[data-disable]").on("change", function(e) {
    if ($(e.currentTarget).is(":checked")) {
      return $(e.currentTarget).parents(".element").find($(e.currentTarget).data("disable")).attr("disabled", "disabled");
    } else {
      return $(e.currentTarget).parents(".element").find($(e.currentTarget).data("disable")).removeAttr("disabled");
    }
  });

  $(".archived").on("click", function(e) {
    if ($(e.currentTarget).is(":checked")) {
      return $(".display-active").attr("checked", false);
    }
  });

  $(".display-active").on("click", function(e) {
    var selector, val;
    if ($(e.currentTarget).is(":checked")) {
      $(".archived").attr("checked", false);
    }
    selector = $(this).data("toggle");
    val = $(this).is(":checked");
    return $(selector).toggle(val);
  });

  $("[data-toggle]").not(".note-color-btn").on("click", function() {
    var selector, val;
    selector = $(this).data("toggle");
    val = $(this).is(":checked");
    return $(selector).toggle(val);
  });

  $(".panel-group").on("show.bs.collapse", function(e) {
    var element;
    element = $(e.currentTarget);
    return element.find(".panel-heading input").prop("checked", true).trigger("change");
  });

  $(".panel-group").on("hide.bs.collapse", function(e) {
    var element;
    element = $(e.currentTarget);
    return element.find(".panel-heading input").prop("checked", false).trigger("change");
  });

  update_form_with_richtext = function(elt) {
    var textarea;
    textarea = elt.parents(".rich-text-element").find("textarea");
    textarea.html(elt.code());
    return $("#save").removeClass("btn-success").addClass("btn-primary").html("Save");
  };

  sendFile = function(file, callback) {
    var reader;
    reader = new FileReader();
    reader.onload = function(event) {
      var data;
      data = {
        image: reader.result
      };
      return $.ajax({
        url: '/upload/image',
        data: data,
        type: 'POST',
        success: function(data) {
          return callback(data);
        }
      });
    };
    return reader.readAsDataURL(file);
  };

  data_add = function(e) {
    var $elt, add_to, count, index, inside, new_rule, ref, replace, to_add, to_clean;
    e.preventDefault();
    $elt = $(e.currentTarget);
    to_add = $elt.data("add");
    to_clean = (ref = $elt.data("clean")) != null ? ref : to_add;
    add_to = $elt.data("add-to");
    inside = $elt.data("inside");
    replace = $elt.data("replace-count");
    count = $elt.data("count");
    new_rule = (inside ? $elt.parents(inside).find(to_add) : $(to_add)).clone().removeClass(to_clean.replace(/^./, ""));
    (inside ? $elt.parents(inside).find(add_to) : $(add_to)).append(new_rule);
    new_rule.show().removeClass("hide").removeClass(to_clean);
    index = $(add_to).children().size();
    if (index === 1) {
      new_rule.find(".first").remove();
    }
    return new_rule.find("input[name],textarea[name]").not("[class*='note-']").each(function() {
      var name, search_name;
      name = $(this).attr("name");
      if (replace != null) {
        search_name = name.split(/\[([0-9]+)\]/)[0];
        index = $("[name*='" + search_name + "']").length - 2;
        name = name.replace(/\[\d+\]/, "[" + index + "]").replace(/\[\d+\]$/, "");
      } else {
        search_name = name.replace(/\[\]/g, "");
        index = $("[name*='" + search_name + "']").length - 2;
        name = search_name + ("[" + index + "]");
      }
      $(this).attr("name", name);
      if ($(this).attr("type") === "checkbox") {
        $(this).val(index);
      }
      $(this).on("change", function() {
        return $("#save").removeClass("btn-success").addClass("btn-primary").html("Save");
      });
      $(document).trigger("data-added", new_rule);
      $("#item-pick-modal").trigger("new-trigger");
      $(document).off("click", "[data-add]", data_add);
      return $(document).on("click", "[data-add]", data_add);
    });
  };

  return GenericFormView;

})(Backbone.View);

module.exports = GenericFormView;
