var FreeShipping,
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

FreeShipping = (function(superClass) {
  extend(FreeShipping, superClass);

  function FreeShipping() {
    return FreeShipping.__super__.constructor.apply(this, arguments);
  }

  FreeShipping.prototype.select_is_global = function() {
    if ($("[name='free_shipping.is.global']").is(":checked")) {
      $("#trigger-items").hide();
      return $("#excluded-items").show();
    } else {
      $("#trigger-items").show();
      return $("#excluded-items").hide();
    }
  };

  FreeShipping.prototype.initialize = function() {
    $("[name='free_shipping.is.global']").on("change", this.select_is_global);
    return this.select_is_global();
  };

  return FreeShipping;

})(Backbone.View);

module.exports = FreeShipping;
